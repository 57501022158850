import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Util } from './util';
import { Component, Input } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable()
export class HttpServer {

  constructor(
    private util: Util,
    private loc: Location
  ) {
  }

  SendData(
    model: any,
    url: any,
    mode: string,
    isBannerNeed = true,
    isDirectUrl = false,
    token = null,
    bannerId='1',
  ) {

    if (isDirectUrl === true) {
      url = url;
    }
    else {
      const IsCSPUrl = environment.CSPUrl.find((x: any) => x.toString().toLowerCase() === url.toString().toLowerCase())
      if (this.util.isDev()) {
        url = (this.util.IsNotNull(IsCSPUrl) ?
        environment.devShoreCSPAPI : environment.devShoreAPI) + '/' + url;
      }
      else if (this.util.isUAT()) {
        url = (this.util.IsNotNull(IsCSPUrl) ?
          environment.uatShoreCSPAPI : environment.uatShoreAPI) + '/' + url;
      }
      else if (this.util.isProd()) {
        url = (this.util.IsNotNull(IsCSPUrl) ?
        
          environment.prodShoreCSPAPI : environment.prodShoreAPI) + '/' + url
      }
      else {
        url = (this.util.IsNotNull(IsCSPUrl) ?
          environment.devShoreCSPAPI : environment.devShoreAPI) + '/' + url;
          

        // url = (this.util.IsNotNull(IsCSPUrl) ?
        // environment.offShoreCSPAPI : environment.offShoreAPI) + '/' + url;

        //url = environment.devShoreAPI + '/' + url;
        //url = environment.OffShoreAPI + '/' + url;
      }
    }

    let headers: any;
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
      Banner: ''
    };

    if (url.includes('https://7py8fahnf3.execute-api.us-west-1.amazonaws.com/v1/profile/update/Inmar')) {
      if (bannerId != null && bannerId.toString() == "2") {
        headers['x-retailer'] = 'luckysuper';
      } else if (bannerId != null && bannerId.toString() == "3") {
        headers['x-retailer'] = 'foodmaxx';
      } else {
        headers['x-retailer'] = 'savemartdpn';
      }
      headers['origin'] = '*';  
    }
  

    if (url.includes("7py8fahnf3") || url.includes("llz1npmgm7")) {
      if (bannerId != null && bannerId.toString() == "2") {
        headers.Banner = 'luckysupermarkets';
      }
      else if (bannerId != null && bannerId.toString() == "3") {
        headers.Banner = 'foodmaxx';
      }
      else {
        headers.Banner = 'savemart';
      }
    }

    if (!this.util.IsNotEmpty(headers.Banner)) {
      delete headers.Banner
    }
    if (!this.util.IsNotEmpty(token)) {
      token = this.util.getToken();
    }

    if (this.util.IsNotEmpty(token)) {
      headers.Authorization = 'Bearer ' + token;
    }

    if (!this.util.IsNotEmpty(headers.Authorization)) {
      delete headers.Authorization;
    }
    delete headers.Authorization;

    return fetch(url, {
      method: mode ? mode.toUpperCase() : 'GET',
      headers,
      body: model ? JSON.stringify(model) : null,
    }).
      then((Response) => Response.clone().json()).
      catch(function () {
        console.log("error");
      });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
